<template>
    <Dialog title="" width="1080px" :visible="ChooseProductDialogShow" class="ChooseProductBox f-cl-c"
        @closeDialog="closeDialog">
        <div class="f-c-b">
            <el-select class="selectBox" v-model="optionVal" @change="nameNumberSelectOptionVal">
                <el-option v-for="(i, idx) of nameNumberOption" :label="i.label" :value="i.value" :key="idx"></el-option>
            </el-select>
            <div class="f-c-c">
                <div class="f-c search-input">
                    <input type="text" placeholder="请输入产品名称" v-model.trim="searchValue" @keyup.enter="search"
                        maxlength="10" />
                </div>
                <el-button class="search-icon" @click="search" icon="el-icon-search"></el-button>
            </div>
        </div>



        <div v-if="isSearch" class="tips">搜索到 {{ total }} 个与 “{{ searchValue }}” 相关产品</div>
        <div class="product-list" v-if="ProductList && ProductList.length">
            <div class="list-inner">
                <div class="product-item f-c-c " v-for="(item, index) in ProductList" :key="index" @click="choose(item)">
                    <el-image class="item-image" :src="item.img1Url" fit="fill"></el-image>
                    <div class="f-c-b f1">
                        <div class="left f-cl w--75">
                            <span class="productName">{{ item.productName }}</span>
                            <span class="productNumber" v-if="item.productNumber">{{ item.productNumber ? item.productNumber : '未设置' }}</span>
                            <span v-if="item.unitPrice">
                                <span style="font-size: 19px;">{{ item.valuationCurrency }}</span>
                                <span style="font-size: 24px;">{{ item.unitPrice }}</span>
                            </span>
                            <span v-else  style="font-size: 19px;">暂无报价</span>
                        </div>
                        <div class="right f-cl-c f1">
                            <div class="productTypeStr">
                                {{ item.productTypeStr }}
                            </div>
                            <span>{{
                                item.inventoryQuantity
                                == 0 ? '缺货' : (item.shortageFlag == 'Y' ? '对外显示缺货' : '在售')
                            }}</span>
                            <span>库存数量：{{ item.inventoryQuantity }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination :total="total" :margin="'10px'" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </div>
        <el-empty v-else description="请先添加产品！" class="no-list"></el-empty>
    </Dialog>
</template>

<script>

import { UnitAccount } from "@/utils/attributeValue1Options";
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
import { nameNumberOption } from '@/utils/SelectOption'
export default {
    name: 'ChooseProductBox',
    mixins: [PaginationRetention1],
    props: {
        ChooseProductDialogShow: {//是否显示邀请盒子
            type: Boolean,
            default: false
        }
    },
    watch: {
        ChooseProductDialogShow(newName) {
            if (newName) {
                this.findSearchEnterpriseProductList(this.paging, this.pagingSize)
            }
        }
    },
    data() {
        return {
            searchValue: '',
            ProductList: [],
            isSearch: false,//是否启动搜索
            nameNumberOption: nameNumberOption,
            optionVal: '0',
        }
    },
    methods: {
        getDate() {
            this.findSearchEnterpriseProductList(this.paging, this.pagingSize, this.searchValue)
        },
        // 获取下拉框的值
        nameNumberSelectOptionVal(value) {
            console.log(value)
            this.optionVal = value;
        },
        // 搜索列表
        search() {
            this.searchValue ? this.isSearch = true : this.isSearch = false;
            // this.findSearchEnterpriseProductList()
            this.getDate();
        },
        // 获取产品列表
        findSearchEnterpriseProductList(page = this.page, size = this.size, searchValue = this.searchValue) {
            let that = this;
            that.$http.findSearchEnterpriseProductList({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size,
                openSearchFlag: '',//查全部
                searchProductFlag: that.optionVal,
                searchProduct: searchValue
            }).then(res => {
                if (res.data.records && res.data.records.length) {
                    res.data.records.forEach((item) => {
                        item.customerUseName = item.productName;
                        item.note = '';
                        item.productTypeStr = UnitAccount.getproductType(item.productType)
                    })
                }
                that.ProductList = res.data.records;
                that.currentPage = res.data.current;//当前页数
                that.total = res.data.total;
            })
        },
        closeDialog() {
            this.ProductList = []
            this.searchValue = ''
            this.$emit('closeProductDialog', false)
            this.$removeStorage('fetchDataPage')
            this.$removeStorage('fetchDataSize')
        },
        // 选中商品明细
        choose(data) {
            let selectProductList = this.$getStorage('selectProductList') ? this.$getStorage('selectProductList') : [],
                isSelected = false;
            selectProductList.forEach(item => {
                if (item.productId == data.productId) {
                    isSelected = item.isSelected ? item.isSelected : true
                }
            })
            if (data.inventoryQuantity == 0) {
                return this.$common.message('您的库存已经见底啦!', 'warning', 1800)
            } else if (isSelected) {
                return this.$common.message('您已经加入过该商品啦!', 'warning', 1800)
            } else if (selectProductList.length == 5) {
                return this.$common.message('最多可添加5个商品!', 'warning', 1800)
            } else {
                let chooseProduct = {
                    productId: data.productId,
                    customerUseName: data.customerUseName,//原品名
                    productName: data.productName,//品名
                    customerUseNumber: data.productNumber ? data.productNumber : '',//自定义编号
                    productNumber: data.productNumber ? data.productNumber : '',//原编号
                    quantity: 1,
                    inventoryQuantity: data.inventoryQuantity,
                    originUnitPrice: data.unitPrice,//原价格
                    visibleInventoryQuantigy: false,//是否显示现有库存
                    visibleProductName: false,//是否显示品名popover
                    visibleProductNumber: false,//是否显示编号popover
                    visibleUnitPrice: false,//是否显示价格popover
                    unitPrice: data.unitPrice,
                    LastTransactionPrice: '',//上一次交易价格
                    //默认情况下自动小计和实际小计一致，由于小计可以改变，则变动的小计则为实际小计
                    auAmount: Number(data.unitPrice) * Number(1),//自动小计=价格*数量
                    amount: Number(data.unitPrice) * Number(1),//实际小计
                    isSelected: true,
                    note: data.note,
                    valuationCurrency: data.valuationCurrency
                }
                this.$emit('selectedProduct', chooseProduct)
            }
        }
    }
}

</script>
<style lang='less' scoped>
@import '../../style/productList.less';

/deep/ .el-dialog__body {
    padding: 30px 60px;
    border-radius: 10px;
}

.ChooseProductBox {
    z-index: 2 !important;
    padding: 60px;

    .tips {
        margin: 10px 0;
    }



    .selectBox {
        /deep/ input {
            height: 60px;
            width: 120px;
            font-size: 18px;
        }

        /deep/.el-popper {
            font-size: 18px;
        }

        // border: 1px solid #606266;

        // /deep/.el-input {
        //     height: 60px;
        //     width: 120px;
        // }
        // /deep/ .el-input--suffix{
        //     height: 60px;
        //     width: 120px;
        // }

    }

    .search-input {
        position: relative;

        input {
            background-color: #FFF;
            border-radius: 4px;
            border: 1px solid #E0E1E3;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            height: 60px;
            line-height: 50px;
            outline: 0;
            padding: 0 50px 0 20px;
            transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
            width: 700px;
            font-size: 20px;
        }
    }

    .search-icon {
        width: 100px;
        height: 60px;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        color: #FFFFFF;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
    }

    .product-list {
        margin-top: 20px;
        border-radius: 10px;

        .list-inner {
            height: 500px;
            overflow-y: scroll;

            ::-webkit-scrollbar-vertical {
                display: none;
                /* 将垂直滚动条隐藏起来 */
            }


            .product-item {
                background: #F8F8F8;
                border-radius: 10px;
                height: 105px;
                padding: 15px 40px;
                margin-bottom: 20px;

                .item-image {
                    border-radius: 10px;
                }

                .left {
                    .productName {
                        font-size: 20px;
                        color: #000;
                    }

                    .productNumber {
                        color: #999999;
                        font-size: 16px;
                    }
                }
            }
        }


    }

    /* 隐藏垂直滚动条 */
    .product-list ::-webkit-scrollbar-vertical {
        display: none;
        /* 将垂直滚动条隐藏起来 */
    }
}
</style>
